import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"

const classes = {
  wrapper: 'mb-6',
  name: 'font-semibold text-gray-900 pb-1',
  content: 'mb-2 flex gap-4 flex-col items-center sm:flex-row sm:items-start',
  description: 'text-md text-gray-600 font-light',
  picture: 'mb-1 rounded sm:flex-grow-0 sm:flex-shrink-0 sm:w-1/3',
  links: 'flex gap-4',
  link: 'font-semibold text-blue-700 text-sm hover:underline'
};

const ProjectItem = ({ name, description, link = null, demo = null, picture = null }) => {
  let headerLink = null;
  if (link) {
    headerLink = <a href={link}>{name}</a>;
  }

  let projectLink = null;
  if (link) {
    projectLink = <a className={classes.link} href={link}>View project →</a>;
  }

  let demoLink = null;
  if (demo) {
    demoLink = <a className={classes.link} href={demo}>Demo →</a>;
  }

  return (
    <div className={classes.wrapper}>
      <h3
        className={`${classes.name} ${headerLink ? 'hover:underline hover:text-black' : ''
          }`}
      >
        {headerLink ? headerLink : name}
      </h3>
      <div className={classes.content}>
        {picture && <GatsbyImage className={classes.picture} image={picture} alt={description} />}
        <p className={classes.description}>{description}</p>
      </div>

      <div className={classes.links}>
        {
          projectLink
        }
        {
          demoLink
        }
      </div>
    </div>
  );
};

export default ProjectItem;