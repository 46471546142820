import React, { useMemo } from 'react';

import Section from '../section';
import ProjectItem from '../project-item';
import { graphql, useStaticQuery } from 'gatsby';

const SectionProjects = ({ projects }) => {
  const { allFile: projectPictures } = useStaticQuery(graphql`
    {
      allFile(filter: {sourceInstanceName: {eq: "projects"}}) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
    } 
  `);

  const nameToPic = useMemo(() => {
    return new Map(projectPictures.edges.map(({ node }) => [node.base, node.childImageSharp.gatsbyImageData]));
  }, [projectPictures]);

  if (!projects.length) return null;

  return (
    <Section title="Projects">
      {projects.map((project) => {
        console.log(project)
        let picture = undefined;
        if (project.picture) {
          picture = nameToPic.get(project.picture);
        }
        return <ProjectItem
          key={project.name}
          name={project.name}
          description={project.description}
          link={project.link}
          demo={project.demo}
          picture={picture}
        />
      }

      )}
    </Section>
  );
};

export default SectionProjects;
